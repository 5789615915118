<template>
  <div class="card">
    <h1>Historia</h1>
    <p>
      <b>INCE (Inspira, crea en confianza)</b> nace como una sociedad creada para contribuir en el desarrollo económico de
      las mujeres y sus familias creyendo en ellas con créditos a la palabra para la inversión en sus negocios.
    </p>
    <p>
      A través de sus asesores, un equipo de jóvenes apasionados por el servicio y preocupados por mejorar su vida y la de sus comunidades,
      trabajan día con día creando soluciones que les permitan a ellos y a sus clientes resolver los retos diarios y
      cumplir sus metas.
      </p>
  <p>
      Teniendo como aliados la honestidad y el trabajo en equipo inspirando siempre a más personas a dar lo mejor
      por nosotros, por nuestras familias y por nuestro país.
    </p>
  </div>
</template>
<script>

export default {
  name: 'Historia',
}

</script>

<style scoped>

</style>