<template>
  <div class="container">

    <div class="header">
      <img src="images/logo.png" class="logo"/>
    </div>

    <div class="contenidoPrincipal" :class="{'row': esHorizontal}">
      <div :class="{'col-4': esHorizontal}">
        <div class="card">
          <ul class="menu" :class="{'vertical': esVertical}">
            <li>Inicio</li>
            <li data-value="1" @click="cambiarPagina($event)">Misión/Visión</li>
            <li data-value="2" @click="cambiarPagina($event)">Valores</li>
            <li data-value="3" @click="cambiarPagina($event)">Historia</li>
            <li data-value="4" @click="cambiarPagina($event)">Servicios Ofrecidos</li>
            <li data-value="5" @click="cambiarPagina($event)">Contacto</li>
            <li>
              <a href="avisoDePrivacidad.pdf" target="_blank">Aviso de privacidad</a>
            </li>
          </ul>
        </div>
      </div>
      <div :class="{'col-8': esHorizontal}">
        <Mision v-if="paginaActiva==1"></Mision>
        <Valores v-if="paginaActiva==2"></Valores>
        <Historia v-if="paginaActiva==3"></Historia>
        <ServiciosOfrecidos v-if="paginaActiva==4"></ServiciosOfrecidos>
        <Contacto v-if="paginaActiva==5"></Contacto>
      </div>
    </div>
  </div>
</template>

<script>

import Mision from "./mision";
import Valores from './valores';
import Historia from './historia';
import ServiciosOfrecidos from './servicios-ofrecidos';
import Contacto from './contacto';

export default {
  name: 'Inicio',
  components: {
    Mision,
    Valores,
    Historia,
    ServiciosOfrecidos,
    Contacto
  },
  data() {
    return {
      paginaActiva: 4,
      orientation: '',
      esHorizontal: true,
    }
  }, //data
  methods: {
    cambiarPagina(event) {
      this.paginaActiva = event.target.dataset.value;
    },
  }, //methods
  computed: {
    esVertical: function () {
      return !this.esHorizontal;
    }
  }, //computed
  created() {
    if(screen.orientation === undefined)
    {
      if(window.screen.width < window.screen.height)
      {
        this.esHorizontal = false;
      }
    }
    else {
      const orientation = (screen.orientation || {}).type;
      this.esHorizontal = orientation.includes('landscape');
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: #aaaaaa;
}
</style>
