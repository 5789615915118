<template>
  <div class="card">
    <h1>Valores</h1>
    <ul class="valores">
      <li>HONESTIDAD</li>
      <li>LIDERAZGO</li>
      <li>RESPONSABILIDAD</li>
      <li>TRABAJO EN EQUIPO</li>
      <li>COMUNICACIÓN</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Valores',
}
</script>

<style scoped>
ul.valores {
  list-style: none;
  text-align: left;
}

ul.valores li {

}

</style>
