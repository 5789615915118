<template>
  <div class="card">
        <h1>Misión</h1>

        <p>
          Satisfacer y superar las expectativas de clientes y socios, mediante un sistema sustentable de créditos e
          inversiones en un sector clave en el desarrollo de nuestras comunidades.
        </p>

            <h1>Visión</h1>

        <p>
          Ser una opción financiera, disruptiva y consolidada dentro del mercado, dando oportunidad a las y los
          emprendedores con microcréditos para el mejoramiento de la economía en sus negocios y proyectos productivos,
          dentro del estado de Morelos.
        </p>
  </div>
</template>

<script>

export default {
  name: 'Mision',
  components: {
  }
}
</script>
<style scoped>
</style>