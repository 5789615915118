<template>
  <div class="card">

    <p>
      Háganos llegar sus dudas y comentarios llenando y envíando el siguiente formulario y nostros nos pondremos en contacto con usted.
    </p>

    <InputText v-model="nombre" placeholder="Nombre" />
      <InputText v-model="email" placeholder="Correo electrónico"/>
      <InputText v-model="telefono" placeholder="Teléfono"/>
      <Textarea v-model="comentarios" placeholder="Dudas y comentarios"></Textarea>
    <Button label="Enviar" @click="enviarEmail"></Button>
  </div>
</template>


<script>
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';

export default {
  name: "Contacto",
  components: {
    InputText,
    Textarea,
    Button
  },
  data() {
    return {
      nombre: '',
      email: '',
      telefono: '',
      comentarios: '',
    }
  }, //data
  methods: {
    enviarEmail: function(){
        const data = {
          nombre: this.nombre,
          email: this.email,
          telefono: this.telefono,
          comentarios: this.comentarios
        };

        //const url = "http://localhost/EnConfianza/web/app_dev.php/public/webpage/sendmail";
        const url = "http://app.ince.mx/web/app_dev.php/public/webpage/sendmail";
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify( data )
        })
        .then( response => {
          console.log(response);
          alert("Gracias por ponerse en contacto con nosotros, en breve le atenderemos.");
        });
    }
  }, //methods
}
</script>
