<template>
  <div class="card">
    <h1>Nuestros Servicios</h1>
    <p>
      <b>INCE, INSPIRA, CREA EN CONFIANZA. S.A.S. DE C.V.</b>, tiene para ti microcréditos individuales que van desde
      los
      $2,000.00 a los $40,000.00 con tasa de interés preferenciales para nuestros clientes puntuales y comprometidos.
    </p>


    <div class="politicas">
      <h1>Políticas</h1>
      <p>
        Es mejor si todos nos apoyamos si ya eres cliente recomienda a alguien y ayúdalo a obtener su crédito más
        rápido.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Servicios Ofrecidos',
}
</script>

<style scoped>
  div.politicas {
    margin-top: 40px;
  }
</style>